import { toast } from 'sonner';
import { Navigate } from 'react-router';

const PermissionDenied = () => {
    toast.error('Permission Denied', {
        id: 'permission-denied',
        duration: 7000,
    });

    return <Navigate to={'/'} />;
};

export default PermissionDenied;
