import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useUser } from '../../lib/useUser';
import { useUserContext } from '../../contexts/UserContext';

const AuthRedirect = () => {
    const { user } = useUserContext();
    const currentRoute = window.location.pathname;

    if (user === undefined) {
        // TODO: Add loading spinner
        return null;
    }

    return user !== null ? (
        <Outlet />
    ) : (
        <Navigate
            to={'/login'}
            state={{
                from: currentRoute,
            }}
        />
    );
};

export default AuthRedirect;
