import React from 'react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const ProtectedRoute = ({ roles, fallbackRoute }) => {
    const { user } = useUserContext();

    if (!roles || roles.some((role) => user?.roles?.includes(role))) {
        return <Outlet />;
    } else {
        return (
            <Navigate
                to={{
                    pathname: fallbackRoute,
                }}
            />
        );
    }
};

export default ProtectedRoute;
