import Icon from '../../components/ui/Icon';
import { Button } from '../../components/ui/Button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../components/ui/Card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../components/ui/DropdownMenu';
import { Sheet, SheetContent, SheetTrigger } from '../../components/ui/Sheet';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo192.png';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '../../components/ui/Collapsible';
import { useEffect, useState } from 'react';
import { ThemeToggle } from '../../components/ui/ThemeToggle';
import { useTheme } from '../../contexts/ThemeContext';
import { useNavItems } from '../../routes/constants';

const AppLayout = () => {
    const [open, setOpen] = useState(false);
    const { theme } = useTheme();
    const navigate = useNavigate();
    const navItems = useNavItems();
    const location = useLocation();
    const currentNavItem = navItems.flatMap((item) => [item, ...item.children]).filter((item) => item.path === location.pathname)[0];
    const currentPath = currentNavItem?.path;

    useEffect(() => {
        if (currentNavItem) {
            setOpen(true);
        }
    }, [currentNavItem]);

    return (
        <div className="grid h-dvh w-full md:grid-cols-[220px_1fr] lg:grid-cols-[220px_1fr] bg-background overflow-hidden">
            <div className="hidden border-r bg-background md:block h-screen">
                <div className="flex h-full max-h-screen flex-col gap-2">
                    <div className="flex h-14 items-center border-b px-4 lg:h-16 lg:px-6 bg-background">
                        <Link to="/app" className="flex items-center gap-3 font-semibold">
                            <img src={Logo} alt={'distillify-logo'} className="h-8 w-8" />
                            <span className="text-foreground">Distillify</span>
                        </Link>
                        <Button variant="outline" size="icon" className="ml-auto h-8 w-8">
                            <Icon.Bell className="h-4 w-4 text-muted-foreground" />
                            <span className="sr-only">Toggle notifications</span>
                        </Button>
                    </div>
                    <div className="flex-1">
                        <nav className="grid h-full items-start px-2 text-sm font-medium lg:px-4">
                            {navItems.map(({ children, title, path, Icon: NavIcon }) => {
                                const isSelected = children ? children.some((child) => child.path === currentPath) : path === currentPath;

                                if (children)
                                    return (
                                        <Collapsible key={title} open={open} onOpenChange={setOpen}>
                                            <CollapsibleTrigger className={`group flex w-full justify-between items-center px-3 py-2`}>
                                                <div
                                                    className={`gap-3 text-muted-foreground flex flex-row items-center group-hover:text-primary ${isSelected ? 'text-primary' : ''}`}
                                                >
                                                    <NavIcon className="h-4 w-4" />
                                                    {title}
                                                </div>
                                                <Icon.ChevronDown
                                                    className={`transform ${open ? 'rotate-180' : 'rotate-0'} transition duration-250 ease-in-out h-4 w-4 text-muted-foreground group-hover:text-primary ${isSelected ? 'text-primary' : ''}`}
                                                />
                                            </CollapsibleTrigger>
                                            <CollapsibleContent className={'ml-5 border-l-2'}>
                                                <div className="ml-1">
                                                    {children.map(({ title, path, Icon: ChildNavIcon }) => (
                                                        <Link
                                                            key={title}
                                                            to={path}
                                                            className={`flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary ${currentPath === path ? 'text-primary' : ''}`}
                                                        >
                                                            <ChildNavIcon className="h-4 w-4" />
                                                            {title}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </CollapsibleContent>
                                        </Collapsible>
                                    );
                                return (
                                    <Link
                                        key={title}
                                        to={path}
                                        className={`flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary ${isSelected ? 'text-primary' : ''}`}
                                    >
                                        <NavIcon className="h-4 w-4" />
                                        {title}
                                    </Link>
                                );
                            })}
                            <div className={'self-end py-4'}>
                                <Link
                                    to={'/privacy'}
                                    className="flex items-center gap-3 rounded-lg px-3 py-1 text-muted-foreground transition-all hover:text-primary"
                                >
                                    Privacy Policy
                                </Link>
                                <Link
                                    to={'/terms'}
                                    className="flex items-center gap-3 rounded-lg px-3 py-1 text-muted-foreground transition-all hover:text-primary"
                                >
                                    Terms and Conditions
                                </Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="h-full overflow-hidden">
                <header className="flex h-14 items-center gap-4 border-b bg-background px-4 lg:h-16 lg:px-6">
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button variant="outline" size="icon" className="shrink-0 md:hidden">
                                <Icon.Menu className="h-5 w-5 text-muted-foreground" />
                                <span className="sr-only">Toggle navigation menu</span>
                            </Button>
                        </SheetTrigger>
                        <SheetContent side="left" className={`flex flex-col ${theme}`}>
                            <nav className="grid gap-2 text-lg font-medium">
                                <Link to="#" className="flex items-center gap-2 text-lg font-semibold">
                                    <img src={Logo} alt={'distillify-logo'} className="h-8 w-8" />
                                    <span className="sr-only">Distillify</span>
                                </Link>
                                {navItems.map((item) => {
                                    if (item.children)
                                        return (
                                            <Collapsible key={item.title} open={open} onOpenChange={setOpen}>
                                                <CollapsibleTrigger className="flex w-full justify-between items-center mx-[-0.65rem] gap-4 rounded-xl px-3 py-2">
                                                    <div className="gap-4 text-muted-foreground flex flex-row items-center">
                                                        <Icon.FileLock className="h-5 w-5" />
                                                        {item.title}
                                                    </div>
                                                    <Icon.ChevronDown
                                                        className={`transform ${open ? 'rotate-180' : 'rotate-0'} transition duration-250 ease-in-out h-5 w-5 text-muted-foreground`}
                                                    />
                                                </CollapsibleTrigger>
                                                <CollapsibleContent className={'ml-3 border-l-2'}>
                                                    <div className="ml-5">
                                                        {item.children.map(({ title, path, Icon: ChildNavIcon }) => (
                                                            <Link
                                                                key={title}
                                                                to={path}
                                                                className={`mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground ${currentPath === path ? 'text-primary' : ''}`}
                                                            >
                                                                <ChildNavIcon className="h-5 w-5" />
                                                                {title}
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </CollapsibleContent>
                                            </Collapsible>
                                        );
                                    return (
                                        <Link
                                            key={item.title}
                                            to={item.path}
                                            className="mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground"
                                        >
                                            <Icon.Home className="h-5 w-5" />
                                            {item.title}
                                        </Link>
                                    );
                                })}
                            </nav>
                            <div className="mt-auto">
                                <Card>
                                    <CardHeader>
                                        <CardTitle>Upgrade to Pro</CardTitle>
                                        <CardDescription>Unlock all features and get unlimited access to our support team.</CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <Button size="sm" className="w-full">
                                            Upgrade
                                        </Button>
                                    </CardContent>
                                </Card>
                            </div>
                        </SheetContent>
                    </Sheet>
                    <div className="w-full flex-1"></div>
                    <ThemeToggle />
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="icon" className="rounded-full bg-background">
                                <Icon.CircleUser className="h-5 w-5 text-muted-foreground" />
                                <span className="sr-only">Toggle user menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className={theme}>
                            <DropdownMenuItem onSelect={() => navigate('/login')}>Logout</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </header>
                <main className="overflow-hidden md:h-[calc(100vh-theme(spacing.14))] h-[calc(100vh-theme(spacing.0))] lg:h-[calc(100vh-theme(spacing.16))] flex flex-col">
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

export default AppLayout;
