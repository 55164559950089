import React from "react";
import { Suspense } from "react";
import { Skeleton } from "./ui/Skeleton";

const Loadable = (Component, Fallback) => (props) => (
  <Suspense fallback={Fallback ? <Fallback /> : <Skeleton />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
