import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}
export const toProperCaseWithSpaces = (str = '') => {
    if (!str || !/\w/g.test(str)) return str;
    return str
        .replace(/[^a-zA-Z0-9]/g, ' ')
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(/\s+/)
        .map((w) => w[0].toUpperCase() + w.slice(1))
        .join(' ')
        .replace(/^./, function (str) {
            return str.toUpperCase();
        });
};

export const castToType = (value, type) => {
    switch (type) {
        case 'number':
            return Number(value);
        case 'checkbox':
            return value === 'true' ? true : value === 'false' ? false : Boolean(value);
        case 'object':
            return !value ? value : JSON.parse(value);
        case 'date':
            return new Date(value);
        default:
            return value;
    }
};

export const valueFromType = (value, type) => {
    switch (type) {
        case 'timestamp':
            return new Date(value.seconds + (value.nanoseconds ?? 0) / 1000000).toLocaleString();
        case 'date':
            return value.toISOString();
        case 'string':
            return value.trim();
        case 'number':
            return Number(value);
        default:
            return value;
    }
};

export const tryParseJSON = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
};

export const getInputTypeFromPrimitive = (primitive) => {
    switch (primitive) {
        case 'string':
            return 'text';
        case 'boolean':
            return 'checkbox';
        default:
            return primitive;
    }
};

export const getSchemaFromPrimitive = (primitive, required) => {
    let schema;
    switch (primitive) {
        case 'string':
            if (required) {
                schema = z
                    .string({
                        required_error: 'Please enter a value',
                        invalid_type_error: 'Please enter a valid value',
                    })
                    .trim();
            } else {
                schema = z
                    .string({
                        invalid_type_error: 'Please enter a valid value',
                    })
                    .nullable()
                    .trim();
            }
            break;
        case 'number':
            if (required) {
                schema = z.coerce.number({
                    required_error: 'Please enter a number',
                    invalid_type_error: 'Please enter a valid number',
                });
            } else {
                schema = z.coerce
                    .number({
                        invalid_type_error: 'Please enter a valid number',
                    })
                    .nullable();
            }
            break;
        case 'boolean':
            if (required) {
                schema = z.coerce.boolean({
                    required_error: 'Please enter a boolean',
                    invalid_type_error: 'Please enter a valid boolean',
                });
            } else {
                schema = z.coerce
                    .boolean({
                        invalid_type_error: 'Please enter a valid boolean',
                    })
                    .nullable();
            }

            schema = schema.default(false);
            break;
        case 'array':
            schema = z.optional(z.array(z.any()));
            break;
        case 'date':
            if (required) {
                schema = z.date({
                    required_error: 'Please enter a date',
                    invalid_type_error: 'Please enter a valid date',
                });
            } else {
                schema = z
                    .date({
                        invalid_type_error: 'Please enter a valid date',
                    })
                    .nullable();
            }
            break;
        default:
            if (required) {
                schema = z.any({
                    required_error: 'Please enter a value',
                    invalid_type_error: 'Please enter a valid value',
                });
            } else {
                schema = z.any({
                    invalid_type_error: 'Please enter a valid value',
                });
            }
    }

    if (!required) {
        schema = schema.optional();
    }

    return schema;
};

export const getUserDataFromAuthUser = (user) => {
    return {
        email: user.email,
        uid: user.uid,
        displayName: user.displayName,
        username: user.displayName,
        photoURL: user.photoURL,
    };
};
